// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-legislator-index-js": () => import("./../../../src/components/legislator/index.js" /* webpackChunkName: "component---src-components-legislator-index-js" */),
  "component---src-components-legislator-og-image-js": () => import("./../../../src/components/legislator/ogImage.js" /* webpackChunkName: "component---src-components-legislator-og-image-js" */),
  "component---src-components-sponsorships-index-js": () => import("./../../../src/components/sponsorships/index.js" /* webpackChunkName: "component---src-components-sponsorships-index-js" */),
  "component---src-components-sponsorships-sponsorships-js": () => import("./../../../src/components/sponsorships/sponsorships.js" /* webpackChunkName: "component---src-components-sponsorships-sponsorships-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-legislators-index-js": () => import("./../../../src/pages/all-legislators/index.js" /* webpackChunkName: "component---src-pages-all-legislators-index-js" */),
  "component---src-pages-all-legislators-legislator-list-js": () => import("./../../../src/pages/all-legislators/LegislatorList.js" /* webpackChunkName: "component---src-pages-all-legislators-legislator-list-js" */),
  "component---src-pages-all-legislators-sort-button-index-js": () => import("./../../../src/pages/all-legislators/SortButton/index.js" /* webpackChunkName: "component---src-pages-all-legislators-sort-button-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-index-js": () => import("./../../../src/pages/landing/index.js" /* webpackChunkName: "component---src-pages-landing-index-js" */),
  "component---src-pages-landing-search-form-js": () => import("./../../../src/pages/landing/SearchForm.js" /* webpackChunkName: "component---src-pages-landing-search-form-js" */),
  "component---src-pages-landing-search-instructions-js": () => import("./../../../src/pages/landing/SearchInstructions.js" /* webpackChunkName: "component---src-pages-landing-search-instructions-js" */)
}

